import * as React from "react"

import { Helmet } from 'react-helmet';
import Layout from "../components/layout"
import Footer from "../components/Footer.js"
import { Container } from '@chakra-ui/react'

import PeteButtigiegAnalog from '../images/PeteButtigiegAnalog.png'

import StuckInTrafficSolo from '../images/StuckInTrafficSolo.jpeg'
import makingYourTrainLateSolo from '../images/makingYourTrainLateSolo.jpeg'
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Accordion, AccordionItem, Box, AccordionPanel, AccordionIcon, AccordionButton, Text } from "@chakra-ui/react"
import {
  List,
  ListItem,
  ListIcon,
  UnorderedList,
  Image,
  Circle,
  Button,
  VStack,
  Center,
  Divider,
  SimpleGrid,
  Flex,
  useBreakpointValue
} from '@chakra-ui/react'

import { Highlight, Heading } from '@chakra-ui/react'
import { StaticImage } from "gatsby-plugin-image"

import NewHeader from "../components/NewHeader"

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}
const headingAccentStyles = {
  color: "#663399",
}
const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
}
const listItemStyles = {
  fontWeight: 300,
  fontSize: 24,
  maxWidth: 560,
  marginBottom: 30,
}

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}

const docLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  marginBottom: 24,
}

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
}

const docLink = {
  text: "Documentation",
  url: "https://www.gatsbyjs.com/docs/",
  color: "#8954A8",
}

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
}

const listItemStyle = {
  padding: "4px",
  fontSize: "2xl",
  lineHeight: "120%"
}

const finalListItemStyle = {
  padding: "12px 4px 4px 4px",
  fontSize: "2xl",
  lineHeight: "120%"
}
 

const highlightStyle = {
  px: '1',
  py: '-0.5',
  rounded: 'none',
  bg: 'red.100',
  //lineHeight: "120%"
}

const CarouselSettings = {
    dots: true,
    arrows: false,
    fade: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
}

function Carousel() {
    const cards = [
      StuckInTrafficSolo,
      makingYourTrainLateSolo
    ]

    return (
      <Slider {...CarouselSettings} sx={{border: "none", shadow: "none"}}>
        {
          cards.map( (url, index) => (
              <Box
                key={index}
                height="min(20vh,300px)"
                position="relative"
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                backgroundImage={`url(${url})`}
                border="none"
              />
          ))
        }
      </Slider>
    )
}

/**
 * 
 <StaticImage
          alt="Streets clogged with cars"
          src="../making-your-train-late-solo.jpeg"
          layout="fullWidth"
        />
 */
// markup
/*
<Image
            //src="https://media.npr.org/assets/img/2019/04/02/gettyimages-910282160-9391e05c16dfde24c7c2e3738bfae5ece02065e1.jpg"
            src={makingYourTrainLateSolo}
            alt="traffic"
            objectFit="cover"
            width="100%"
            height="100%"
            //maxHeight="400px"
            top={0}
            left={0}
            right={0}
          /> */
const Index = () => {
  return (<Box><Helmet><title>Good Traffic NYC — Meet Pete</title></Helmet><NewHeader /><Carousel />
  <VStack spacing={0}>
      <Center
        //backgroundImage={makingYourTrainLateSolo}
        //backgroundRepeat="no-repeat"
        //backgroundPosition="center center"
        //backgroundSize="cover"
        //width="100%"
        //height="20vh"
        //minHeight="200px"
        paddingBottom="min(10vh,90px)"
      >
        <Image src={PeteButtigiegAnalog}
          //src='@../images/PeteButtigiegAnalog.png'
          //src='@./images/PeteButtigiegAnalog.png'
          position="absolute"
          alignSelf="center"
          width="min(20vh,180px)"
          border="none"
          shadow="none"
          //top="5%"
        >
        </Image>
      </Center>
      
      
      <Container bg="white" padding="20px">
        <Heading fontSize="4xl">
          US Transportation Secretary Buttigieg is holding up...
        </Heading>
        <Box padding="3">
          <UnorderedList>
            <ListItem sx={listItemStyle}>
                <Highlight
                  query={['billions']}
                  styles={highlightStyle}
                >
                    Billions in saved commute time every year for NYC drivers and truckers
                </Highlight>
            </ListItem>
            <ListItem sx={listItemStyle}>
              <Highlight
                query='40%'
                styles={highlightStyle}
              >
                Modern signals letting the MTA run 40% more trains.
              </Highlight>
            </ListItem>
            <ListItem sx={finalListItemStyle} >
              <Highlight
                query={['fewer','Lower']}
                styles={highlightStyle}
              >
                  Lower emissions and fewer deadly crashes
              </Highlight>
            </ListItem>
          </UnorderedList>
        </Box>
        <Box>
          <Text fontSize="2xl" padding="10px 0px 10px 0px">
            <b>We're here to tell Buttigieg:</b>
          </Text>
        </Box>
        <Center boxShadow="base" rounded="mid" as="a" border="2px" borderColor="gray.300" textAlign="center" bg="pink" href="/action">
              <Text fontSize="4xl" color="black">
                APPROVE CONGESTION PRICING NOW
              </Text>
            
          </Center>
      </Container>
      <Box padding="30px 20px 30px 20px" bg="red.300" width="100%">
        <Container>
          <VStack spacing={{base: 4, sm: 6}}>
            <Text fontSize="2xl">
              <b>New York passed congestion pricing in 2019</b> to cut gridlock, pay for better transit, and curb emissions.
            </Text>
            <Text fontSize="lg">
              Transit advocates and environmental-justice groups strongly support congestion pricing. So does every
    top city and state official.
            </Text>
            <Text fontSize="lg">
              NYC needs congestion pricing to cut harmful traffic. But Buttigieg’s highway engineers have put it on ice.
  They say it needs a massive environmental review. They're wrong.
            </Text>
          </VStack>
        </Container>
      </Box>
    </VStack><Footer/></Box>
  )
}

export default Index
